<template>
    <div class="home">
      <v-layout row wrap class="d-flex justify-center">
        <v-flex xs12 md12 lg12 class="mb-5">
          <h1 class="f-alfa primary--text"><router-link to="/">Pro Track</router-link>  / Add Roles</h1>
        </v-flex>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs12 md6 lg6 class="pa-3">
              <p class="mb-1 grey--text"><b>Role Title / Designation</b></p>
              <v-text-field
                label="Eg. Project Manager"
                single-line
                outlined
                v-model="role"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="pa-3">
             <v-btn x-large  class="primary black--text" @click="createRole">Submit</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import { BASE_URL } from "@/config";
  import Axios from "axios";
  
  export default {
    data() {
      return {
        role: "",
      };
    },
    methods: {
      async createRole() {
        let url = BASE_URL + "/role";
        let payload = {
          role : this.role
        };
        let {data} = await Axios.post(url, payload);
        this.$router.push('/roles')
        this.$toastr.s("New role has been created successfully!", "Role Added");
      },
    },
  };
  </script>